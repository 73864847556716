.subpage {
    padding: 60px 0 40px;

    &-people {
        margin-top: 60px;

        .row {
            &:nth-child(even) {
                .subpage-person-firstcolumn {
                    order: 2;
                }

                .subpage-person-secondcolumn {
                    order: 1;
                }

                .subpage-person-thirdcolumn {
                    order: 3;
                }

                .subpage-person-content {
                    padding-left: 0;
                    padding-right: 40px;

                    &__nomargin {
                        margin-bottom: 0;
                    }

                    &__nopadding {
                        padding: 0;
                    }
                }
            }

            &:nth-child(4n + 1) {
                .subpage-person-image {
                    &:before {
                        left: 10px;
                        top: 10px;
                        height: 44%;
                        width: 5px;
                    }

                    &:after {
                        left: 10px;
                        top: 10px;
                        width: 44%;
                        height: 5px;
                    }
                }
            }

            &:nth-child(4n + 2) {
                .subpage-person-image {
                    &:before {
                        left: 10px;
                        bottom: 10px;
                        height: 44%;
                        width: 5px;
                    }

                    &:after {
                        left: 10px;
                        bottom: 10px;
                        width: 44%;
                        height: 5px;
                    }
                }
            }

            &:nth-child(4n + 3) {
                .subpage-person-image {
                    &:before {
                        right: 10px;
                        bottom: 10px;
                        height: 44%;
                        width: 5px;
                    }

                    &:after {
                        right: 10px;
                        bottom: 10px;
                        width: 44%;
                        height: 5px;
                    }
                }
            }

            &:nth-child(4n + 4) {
                .subpage-person-image {
                    &:before {
                        right: 10px;
                        top: 10px;
                        height: 44%;
                        width: 5px;
                    }

                    &:after {
                        right: 10px;
                        top: 10px;
                        width: 44%;
                        height: 5px;
                    }
                }
            }
        }
    }

    &-person {
        &-image {
            border-radius: 3px;
            box-shadow: 0 0 80px 0 rgba(#252525, 0.2);
            margin-bottom: 65px;
            overflow: hidden;
            position: relative;
            z-index: 0;

            &:before, &:after {
                background-color: $blue;
                content: '';
                position: absolute;
                z-index: 1;
            }
        }

        &-content {
            margin-bottom: 65px;
            padding-left: 40px;

            &__nomargin {
                margin-bottom: 0;
            }

            &__nopadding {
                padding: 0;
            }
        }

        &-name {
            margin-bottom: 15px;
            padding-bottom: 15px;
            position: relative;

            &:after {
                background-color: rgba($blue, 0.4);
                bottom: 0;
                content: '';
                height: 1px;
                left: 0;
                position: absolute;
                width: 60px;
            }
        }
    }

    &-quote {
        align-items: center;
        background-color: $grey3;
        display: flex;
        justify-content: center;
        margin-bottom: 70px;
        margin-top: 10px;
        min-height: 100px;
        overflow: hidden;
        padding: 15px 30px;
        position: relative;
        text-align: center;

        &-text {
            display: inline-block;
            font-size: 24px;
            font-style: italic;
            text-align: center;
            position: relative;
            z-index: 1;
        }

        &-author {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            margin-top: 10px;
            text-align: right;
        }

        &:before, &:after {
            background-image: url('/frontend/img/cudzyslow.svg');
            background-repeat: no-repeat;
            background-size: cover;
            bottom: 0;
            content: '';
            left: 0;
            height: 200px;
            opacity: 0.05;
            position: absolute;
            width: 260px;
        }

        &:after {
            bottom: auto;
            left: auto;
            right: 0;
            top: 0;
        }
    }

    &-frame {
        align-items: center;
        background-color: rgba($blue, 0.1);
        display: flex;
        font-weight: 600;
        height: 100%;
        justify-content: center;
        margin-bottom: 80px;
        min-height: 70px;
        padding: 15px;
        position: relative;
        text-align: center;

        &:before {
            content: "\e90d";
            font-family: 'silberbaum' !important;
            font-size: 18px;
            margin-right: 15px;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
        }

        i {
            font-size: 18px;
            margin-right: 15px;
        }

        &-column {
            margin-bottom: 80px;
        }
    }

    &-thumb {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
        cursor: pointer;
        display: inline-block;
        margin-bottom: 15px;
        margin-right: 15px;
        max-width: 185px;
        min-height: 185px;
        position: relative;
        width: 100%;
        z-index: 0;

        &:last-of-type {
            margin-right: 0;
        }

        &:before {
            background-color: rgba($blue, 0);
            content: '';
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: all .3s ease;
            width: 100%;
            z-index: 1;
        }

        &:hover {
            &:before {
                background-color: rgba($blue, 0.5);
            }
        }

        img {
            height: 100%;
            object-fit: cover;
            object-position: center center;
            position: absolute;
            width: 100%;
        }

        &-row {
            margin-left: -15px;
            margin-right: -15px;

            .subpage-thumb {
                float: left;
                margin-right: 0;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}