.contact {
    padding: 40px 0 40px;

    &-box {
        display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.666;
        margin-bottom: 35px;

        a, p {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.666;
        }

        a {
            &:hover {
                color: rgba($blue, 0.5);
            }
        }
    }

    &-icon {
        color: rgba($blue, 0.4);
        font-size: 60px;
        margin-right: 20px;
    }

    &-form {
        background-color: rgba($blue, 0.05);
        padding: 40px;

        input[type="text"], input[type="email"], input[type='password'], textarea {
            background-color: $white;
            border: 0;
            border-radius: 3px;
            color: black;
            display: block;
            height: 50px;
            margin-bottom: 30px;
            padding: 10.5px 20px;
            resize: vertical;
            transition: box-shadow .5s ease;
            width: 100%;

            &:hover, &:focus {
                box-shadow: 0 0 30px 0 rgba(#252525, 0.1);
                outline: 0;
            }
        }

        textarea {
            min-height: 100px;
        }

        select {
            background-color: $white;
            border: 0;
            color: black;
            display: block;
            height: 50px;
            margin-bottom: 30px;
            padding: 11.5px 20px;
            transition: box-shadow .5s ease;
            width: 100%;

            &:hover, &:focus {
                box-shadow: 0 0 30px 0 rgba(#252525, 0.1);
                outline: 0;
            }

            &:focus {
                outline: 0;
            }
        }

        .list-checkboxes {
            margin-bottom: 20px;

            li:hover label {
                color: black;
                transition: color .3s ease;
                user-select: none;
            }
        }

        input[type="checkbox"] {
            background-color: white;
            border: 1px solid rgba(0, 0, 0, 50);
            cursor: pointer;
            height: 15px;
            margin: 2px 0 0;
            width: 15px;
        }

        label {
            color: rgba($blue, 0.8);
            cursor: pointer;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            transition: color .3s ease;
            width: calc(100% - 20px);
            vertical-align: top;

            &:hover {
                color: rgba($blue, 1);
                transition: color .3s ease;
            }
        }

        .border-red {
            border: 1px solid red !important;

            span {
                color: red !important;
            }
        }

        li.border-red {
            border: none !important;
        }

        .send-form {
            background-color: $blue;
            border: 0;
            border-radius: 3px;
            color: $white;
            display: inline-block;
            font-weight: 700;
            height: 50px;
            padding: 10.5px 20px;
            text-decoration: none;
            text-align: center;
            transition: background-color .3s ease, color .3s ease;
            width: 100%;

            &:hover, &:focus {
                background-color: $lightblue;
                color: $blue;
                transition: background-color .3s ease, color .3s ease;
            }
        }

        .clipboard {
            &-offers {
                .header-clipboard-list {
                    padding: 0 0 30px;
                    pointer-events: none;
                    position: relative;

                    &:before {
                        content: 'Wybrane oferty';
                        display: block;
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                }
                .item-delete {
                    display: none;
                }
            }

            &__english {
                .header-clipboard-list {
                    &:before {
                        content: 'Selected offers';
                    }
                }
            }

            &-input {
                display: none;
            }
        }

        .toggle {
            margin-bottom: 30px;

            &-text {
                font-size: 12px;

                ol {
                    margin-bottom: 5px;

                    > li {
                        counter-increment: step-counter;
                        font-weight: 400;
                        line-height: 1.625;
                        margin-bottom: 5px;
                        padding-left: 20px;
                        position: relative;

                        &:before {
                            content: counter(step-counter) '.';
                            font-size: 12px;
                            position: absolute;
                            left: 0;
                        }
                    }
                }

                ul {
                    margin-bottom: 5px;

                    > li {
                        font-weight: 400;
                        line-height: 1.625;
                        margin-bottom: 5px;
                        padding-left: 20px;
                        position: relative;

                        &:before {
                            content: "\e90d";
                            font-family: 'silberbaum';
                            font-size: 12px;
                            position: absolute;
                            left: 0;
                        }
                    }
                }

                ol ul {
                    margin-top: 5px;
                }

                li {
                    max-height: 100vh;
                    overflow: hidden;
                    transition: all .5s ease;

                    &:not(:first-child) {
                        margin-bottom: 0px;
                        max-height: 0;
                    }
                }

                &.toggled {
                    li:not(:first-child) {
                        margin-bottom: 5px;
                        max-height: 100vh;
                    }
                }
            }

            &-link {
                cursor: pointer;
                display: inline-block;
                font-size: 12px;
                margin-left: 20px;
                text-decoration: underline;
                user-select: none;

                &:hover {
                    color: rgba($blue, 0.5);
                }
            }
        }
    }
}