/*
 *  ======================================= 
 *  COLORS | _colors
 *  =======================================  
 */

$white: white;
$green: #41b483;
$black: #141415;

$lightblue: #d5dce1;
$blue: #231e45;
$darkblue: #0f0d1c;

$grey: #87868e;
$grey2: #F5F7F9;
$grey3: #F0F0F5;

.green {
    color: $green;
}

.grey {
    color: $grey;
}

.lightblue {
    color: $lightblue;
}

.blue {
    color: $blue;
}

.darkblue {
    color: $darkblue;
}