@font-face {
    font-family: 'silberbaum';
    src:  url('../fonts/silberbaum.eot?z4fydw');
    src:  url('../fonts/silberbaum.eot?z4fydw#iefix') format('embedded-opentype'),
    url('../fonts/silberbaum.ttf?z4fydw') format('truetype'),
    url('../fonts/silberbaum.woff?z4fydw') format('woff'),
    url('../fonts/silberbaum.svg?z4fydw#silberbaum') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*
@font-face {
    font-family: 'Constantia';
    src: url('../fonts/Constantia.woff2') format('woff2'),
    url('../fonts/Constantia.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
*/

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'silberbaum' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-adres:before {
    content: "\e900";
}
.icon-cudzyslow:before {
    content: "\e901";
}
.icon-dodaj:before {
    content: "\e902";
}
.icon-plus:before {
    content: "\e90e";
}
.icon-facebook:before {
    content: "\e903";
}
.icon-godziny_pracy:before {
    content: "\e904";
}
.icon-kontakt:before {
    content: "\e905";
}
.icon-linkedin:before {
    content: "\e906";
}
.icon-messenger:before {
    content: "\e907";
}
.icon-schowek:before {
    content: "\e908";
}
.icon-twitter:before {
    content: "\e909";
}
.icon-usun:before {
    content: "\e90a";
}
.icon-lewo:before {
    content: "\e90b";
}
.icon-prawo:before {
    content: "\e90c";
}
.icon-checkmark:before {
    content: "\e90d";
}
.icon-checkmark2:before {
    content: "\e90f";
}