/*
 *  ======================================= 
 *  HOME PAGE | _home_page
 *  =======================================  
 */

.homepage {
    &-jumbotron {
        position: relative;

        &-item {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 750px;
        }

        &-content {
            bottom: 30px;
            position: absolute;
            width: 100%;
        }

        &-nav {
            bottom: 30px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            z-index: 1;

            .owl-prev, .owl-next {
                background-color: $lightblue;
                border: 0;
                border-radius: 3px;
                box-shadow: 0 0 5px 0 rgba($black, 0.3);
                height: 50px;
                margin: 0 5px;
                padding: 0;
                transition: background-color .5s ease, color .5s ease;
                width: 50px;

                &:hover {
                    background-color: $blue;
                    color: $lightblue;
                    outline: 0;
                }

                &:focus {
                    outline: 0;
                }
            }
        }

        .active-box {
            .homepage-box {
                opacity: 1;
            }
        }
    }

    &-box {
        background-color: rgba($blue, 0.7);
        color: $lightblue;
        opacity: 0;
        transition: opacity .5s ease .5s;

        &-header {
            border-bottom: 1px solid rgba($white, 0.3);
            padding: 25px 30px;
        }

        &-footer {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 20px 30px 25px;
        }

        &-title {
            //font-family: 'Constantia', serif;
            font-family: 'Crimson Text', serif;
            font-size: 34px;
        }

        &-description {
            //font-family: 'Constantia', serif;
            font-family: 'Crimson Text', serif;
            font-size: 20px;
        }

        &-price {
            font-size: 24px;
            font-weight: 600;

            small {
                display: inline-block;
                font-size: 14px;
            }
        }

        &-button {
            background-color: transparent;
            border: 1px solid $lightblue;
            color: $lightblue;
            font-weight: 700;

            &:hover {
                background-color: $lightblue;
                color: $blue;
            }
        }
    }

    &-about {
        padding: 40px 0 50px;

        &-subtitle, &-text {
            margin-bottom: 30px;
        }
    }

    &-offer {
        background-color: $grey2;
        overflow: hidden;
        padding: 40px 0 50px;
    }
}