.details {
    &-row {

    }

    &-column {
        align-items: center;
        display: flex;
        margin-bottom: 40px;

        &__right {
            justify-content: flex-end;
        }
    }

    &-title {
        color: $blue;
        display: inline-block;
        font-family: 'Constantia', serif;
        font-size: 24px;
        flex: 1;
        padding-left: 20px;
        position: relative;

        strong {
            font-size: 34px;
        }
    }

    &-price {
        display: inline-block;
        font-size: 24px;
        font-weight: 600;
        padding-right: 30px;

        span {
            display: inline-block;
        }

        small {
            display: inline-block;
            font-size: 14px;
        }
    }

    &-add {
        &.added {
            background-color: $green;
            color: $white;

            .icon-plus:before {
                content: "\e90f";
            }
        }
    }

    &-jumbotron {
        margin-bottom: 30px;
        overflow: hidden;
        position: relative;

        &-background {
            background-color: $lightblue;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: zoom-in;
            min-height: 750px;
            height: 65vw;
            max-height: 1200px;
            position: relative;
        }

        &-bottom {
            margin-top: 20px;
            position: relative;
        }

        &-item {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
            height: 190px;
            position: relative;

            &:before {
                border: 10px solid $white;
                content: '';
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                transition: opacity .5s ease;
                width: 100%;
            }

            &:hover {
                &:before {
                    opacity: 0.5;
                }
            }
        }

        .owl-item {
            &.background-active {
                .details-jumbotron-item {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        &-nav {
            height: 100%;
            left: -25px;
            position: absolute;
            pointer-events: none;
            top: 0;
            width: calc(100% + 50px);
            z-index: 1;

            &.disabled {
                display: none;
            }

            .owl-prev, .owl-next {
                background-color: $lightblue;
                border: 0;
                border-radius: 3px;
                box-shadow: 0 0 5px 0 rgba($black, 0.3);
                height: 50px;
                margin: 0;
                padding: 0;
                pointer-events: all;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: background-color .5s ease, color .5s ease;
                width: 50px;

                &:hover {
                    background-color: $blue;
                    color: $lightblue;
                    outline: 0;
                }

                &:focus {
                    outline: 0;
                }
            }

            .owl-next {
                right: 0;
            }

            &.top-nav {
                left: 15px;
                width: calc(100% - 30px);
            }

            &.disabled {
                display: none;
            }
        }
    }

    &-frame {
        background-color: rgba($blue, 0.05);
        margin-bottom: 30px;
        padding: 14px 20px;

        &-row {
            margin-top: 40px;
        }

        &-name {
            color: rgba($blue, 0.7);
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        &-value {
            font-size: 17px;
            font-weight: 700;
            min-height: 24px;
        }
    }

    &-map {
        margin-bottom: 30px;

        iframe {
            height: 300px;
            width: 100%;
        }
    }

    &-offers {
        background-color: $grey2;
        overflow: hidden;
        padding: 40px 0;
    }
}

.offercarousel {
    &.owl-carousel {
        position: relative;

        .owl-stage-outer {
            overflow: initial;

            &:before {
                position: absolute;
                content: '';
                background: $grey2;
                height: calc(100% + 60px);
                width: 1000%;
                top: -30px;
                left: calc(-1000% - 30px);
                z-index: 1;
            }

            &:after {
                position: absolute;
                content: '';
                background: $grey2;
                height: calc(100% + 60px);
                width: 1000%;
                top: -30px;
                right: calc(-1000% - 30px);
                z-index: 1;
            }
        }
    }

    .owl-dots {
        margin-top: 40px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;

            span {
                width: 10px;
                height: 10px;
                margin: 5px 7px;
                background: rgba($blue, 0.2);
                display: block;
                -webkit-backface-visibility: visible;
                transition: all .5s ease;
                border-radius: 50%;
            }

            &.active span, &.active:hover span {
                background-color: $blue;
                border-radius: 5px;
                width: 30px;
            }

            &:hover span {
                background-color: rgba($blue, 0.5);
            }

            &:focus {
                outline: 0;
            }
        }
    }
}

.offerbox {
    display: block;
    height: 100%;
    transition: box-shadow .5s ease;
    width: 100%;

    &-column {
        margin-bottom: 30px;
    }

    &-image {
        align-items: center;
        background-position: center center;
        background-size: cover;
        display: flex;
        justify-content: center;
        height: 200px;
        position: relative;
        width: 100%;
        z-index: 0;

        &:before {
            background-color: rgba($darkblue, 0);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: background-color .5s ease;
            width: 100%;
            z-index: -1;
        }
    }

    &-button {
        background-color: transparent;
        border: 1px solid $lightblue;
        color: $lightblue;
        opacity: 0;
        transition: opacity .5s ease;
    }

    &-details {
        background-color: $white;
        display: flex;
        flex-direction: column;
        height: calc(100% - 200px);
        min-height: 190px;
        padding: 25px 34px;
    }

    &-header {
        padding-bottom: 30px;
        text-align: center;
    }

    &-title {
        font-family: 'Constantia', serif;
        font-size: 24px;
    }

    &-description {
        font-family: 'Constantia', serif;
        font-size: 18px;
    }

    &-footer {
        align-items: center;
        border-top: 1px solid rgba($blue, 0.2);
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        padding-top: 30px;
    }

    &-price {
        font-size: 20px;
        font-weight: 600;

        small {
            display: inline-block;
            font-size: 12px;
        }
    }

    &-add {
        color: $blue;
        cursor: pointer;
        font-size: 20px;
        transition: color .5s ease;

        &:hover {
            color: rgba($blue, 0.5);
        }

        &.added {
            color: $green;

            &:before {
                content: "\e90f";
            }
        }
    }

    &:hover {
        box-shadow: 0 0 30px 0 rgba(#252525, 0.3);

        .offerbox-image {
            &:before {
                background-color: rgba($darkblue, 0.7);
            }

            .offerbox-button {
                opacity: 1;
                transition: opacity .5s ease .3s;
            }
        }
    }
}

.filter {
    font-size: 0;
    margin-bottom: 40px;

    &__inline-block {
        display: inline-block;
    }

    &-buttonbox {
        font-size: 0;
    }

    &-button {
        background-color: transparent;
        font-weight: 600;
        margin-left: 15px;
        margin-right: 15px;
        text-transform: uppercase;

        &.active {
            background-color: $blue;
            color: $white;
        }

        &-search {
            background-color: $blue;
            color: $white;
            margin-left: auto;
            margin-right: auto;
            margin-top: 23px;
            max-width: 195px;
            width: 100%;

            &:hover {
                background-color: transparent;
                color: $blue;
            }

            &.show-clear {
                max-width: calc(100% - 60px);
            }
        }

        &-clear {
            background-color: $lightblue;
            color: $blue;
            display: none;
            margin-left: 10px;
            margin-right: auto;
            margin-top: 23px;
            max-width: 50px;
            width: 100%;

            &:hover {
                background-color: $blue;
                color: $white;
            }

            &.show-clear {
                display: inline-block;
            }
        }
    }

    &-label {
        color: rgba($blue, 0.5);
        display: block;
        font-size: 13px;
        font-weight: 600;
        text-align: left;
        width: 100%;
    }


    &-group {
        display: inline-block;

        &__margin {
            margin-right: 10px;
        }

        &__fullwidth, &__location {
            display: block;
        }
    }

    &-text {
        color: $blue;
        font-size: 14px;
        font-weight: 600;
        margin-right: 10px;
    }

    &-input {
        &__location {
            width: 100%;
        }

        &__metrage {
            width: 102px;

            &__shorten {
                width: 90px;
            }
        }

        &__price {
            width: 132px;

            &__shorten {
                width: 120px;
            }
        }

        &__rooms {
            text-align: center;
            width: 110px;
        }

        &-placeholder {
            display: inline-block;
            position: relative;

            &:before {
                align-items: center;
                color: rgba($blue, 0.5);
                content: attr(data-placeholder);
                display: flex;
                font-size: 14px;
                font-weight: 600;
                height: 100%;
                justify-content: flex-end;
                padding-right: 17px;
                pointer-events: none;
                position: absolute;
                width: 100%;
            }
        }
    }

    &-text {
        font-weight: 600;
    }
}