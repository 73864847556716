/*
 *  ======================================= 
 *  PROJEKT TWORZONY ZA POMOCA
 *  PREPROCESORA SCSS
 *	JESLI CHCESZ EDYTOWAC COKOLWIEK EDYTUJ
 *  PLIKI SASS  SCSS
 *  =======================================  
 */

@import 'modules/_elements';
@import 'modules/_fonts';
@import 'partials/_colors';
@import 'partials/_contact';
@import 'partials/_global';
@import 'partials/_home_page';
@import 'partials/_offer';
@import 'partials/_subpage';
@import 'partials/_variables';
@import 'vendors/_animate';
@import 'vendors/_magnific-popup';
@import 'vendors/_owlcarousel';