/*
 *  ======================================= 
 *  GLOBAL | _global
 *  =======================================  
 */
// Wstaw tutaj link do dodatkowych czcionek (np. Google Font)
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Crimson+Text:400,700|Roboto+Condensed:300,400,700&display=swap&subset=latin-ext');

html {
    -ms-overflow-style: scrollbar;
}

body {
    color: $blue;
    //font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    font-weight: 400;
    min-width: 320px;
}

/**:not(.no-transition) {
    transition: all .5s ease .1s;
}*/

a {
    color: $blue;
    transition: color .5s ease, background-color .5s ease;

    &:hover, &:focus, &:active {
        color: lighten($blue, 15%);
        text-decoration: none;
    }
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

ul:not([class]) {
    list-style-type: none;
    margin-bottom: 50px;
    padding-left: 0;

    > li {
        font-weight: 400;
        line-height: 1.625;
        margin-bottom: 20px;
        padding-left: 35px;
        position: relative;

        &:before {
            content: "\e90d";
            font-family: 'silberbaum';
            font-size: 18px;
            position: absolute;
            left: 0;
        }
    }
}

ol:not([class]) {
    list-style-type: none;
    margin-bottom: 30px;
    padding-left: 0;

    > li {
        counter-increment: step-counter;
        font-weight: 400;
        line-height: 1.625;
        margin-bottom: 20px;
        padding-left: 35px;
        position: relative;

        &:before {
            content: counter(step-counter) '.';
            font-size: 18px;
            position: absolute;
            left: 0;
        }
    }
}

.cl {
    clear: both;
    width: 100%;
    height: 1px;
    line-height: 1px;
    display: block;
}

.image-to-left {
    float: left;
    margin: 0 20px 15px 0;
}

.image-to-right {
    float: right;
    margin: 0 0 15px 20px;
}

.layout-container {
    padding-left: 0;
    padding-right: 0;
}

.cke_editable {
    padding: 15px 30px;
}

.cke_widget_wrapper {
    margin-left: -15px;
    margin-right: -15px;
}

.cke_widget_element {
    padding-left: 15px;
    padding-right: 15px;
}

table {
    thead, tbody {
        th, td {
            padding: 5px 10px;
        }
    }

    &.nopadding {
        thead, tbody {
            th, td {
                padding: 0;
            }
        }
    }
}

.global {
    &-flexcontent {
        display: flex;
        flex-wrap: wrap;

        &:before, &:after {
            display: none;
        }
    }

    &-alignitemscenter {
        align-items: center;
    }

    &-jumbotron {
        background-position: center 80%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 675px;
        width: 100%;
    }

    &-button {
        align-items: center;
        border: 1px solid $blue;
        border-radius: 3px;
        display: inline-flex;
        font-size: 14px;
        font-weight: 600;
        height: 50px;
        justify-content: center;
        text-align: center;
        transition: background-color .5s ease, color .5s ease;
        width: 195px;

        &__transparent {
            background-color: transparent;
            border: 1px solid rgba($blue, 0.3);
            color: $blue;
            font-weight: 700;

            &:hover {
                background-color: $blue;
                border: 1px solid $blue;
                color: $lightblue;
            }
        }

        &__transparentblue {
            background-color: transparent;
            border: 1px solid $blue;
            color: $blue;
            font-weight: 700;

            &:hover {
                background-color: $blue;
                border: 1px solid $blue;
                color: $white;
            }
        }

        &__singleicon {
            background-color: rgba($blue, 0.1);
            border: 0;
            height: 60px;
            width: 60px;

            &:hover {
                background-color: $blue;
                color: $white;
            }
        }

        &__twoicons {
            background-color: $blue;
            border: 0;
            border-radius: 3px;
            color: $lightblue;
            height: 50px;
            transition: background-color .5s ease, color .5s ease;
            width: 90px;

            i {
                display: inline-block;
                font-size: 22px;
                width: 30px;
            }

            .icon-plus {
                font-size: 30px;
                vertical-align: sub;
            }

            &:hover {
                background-color: $lightblue;
                color: $blue;
            }
        }

        &__blue {
            background-color: $blue;
            border: 1px solid $blue;
            color: $lightblue;
        }

        &__fullwidth {
            width: 100%;
        }

        &:focus {
            outline: 0;
        }
    }

    &-input {
        background-color: transparent;
        border: 1px solid rgba($blue, 0.3);
        border-radius: 3px;
        color: $blue;
        font-size: 14px;
        height: 50px;
        padding: 0 20px;
        transition: border .5s ease, background-color .5s ease;

        &:focus {
            background-color: $white;
            border: 1px solid $blue;
            outline: 0;
        }

        &__fullwidth {
            width: 100%;
        }
    }

    &-margin {
        &-bottom {
            margin-bottom: 50px;
        }
    }

    &-title {
        color: $blue;
        //font-family: 'Constantia', serif;
        font-family: 'Crimson Text', serif;
        font-size: 34px;
        font-weight: 700;
        margin-bottom: 30px;
        padding-left: 20px;
        position: relative;

        &:before {
            background-color: rgba($blue, 0.4);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            width: 1px;
        }
    }

    &-subtitle {
        color: $blue;
        font-size: 24px;
        font-weight: 400;
        position: relative;

        &__smallerfont {
            font-size: 20px;
        }

        &__marginbottom {
            margin-bottom: 20px;
        }

        &__biggermarginbottom {
            margin-bottom: 50px;
        }

        &__biggermargintop {
            margin-top: 50px;
        }

        &__border {
            margin-bottom: 15px;
            padding-bottom: 15px;
            position: relative;

            &:after {
                background-color: rgba($blue, 0.4);
                bottom: 0;
                content: '';
                height: 1px;
                left: 0;
                position: absolute;
                width: 60px;
            }
        }
    }
}

.header {
    background-color: $blue;
    color: $lightblue;
    height: 100px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;

    &-logo {
        align-items: center;
        background-color: $blue;
        display: flex;
        justify-content: center;
        height: 200px;
        left: -15px;
        position: absolute;
        transition: height .5s ease;
        width: calc(100% + 30px);
        z-index: 3;

        &.smaller {
            height: 140px;
            left: 15px;
            width: calc(100% - 30px);

            .header-logo-image {
                max-width: calc(100% - 60px);
            }
        }

        &-image {
            max-width: calc(100% - 60px);
            transition: max-width .5s ease;
        }
    }

    &.small-logo {
        .header-logo {
            height: 100px;
        }

        .header-logo-image {
            max-width: calc(100% - 160px);
        }

        .header-logo.smaller .header-logo-image {
            max-width: calc(100% - 100px);
        }
    }

    &-menu {
        align-items: center;
        display: inline-flex;
        height: 100px;
        justify-content: flex-end;
        margin-bottom: 0;
        list-style-type: none;
        padding-left: 0;
        position: relative;

        li {
            align-items: center;
            display: flex;
            height: 100px;
            margin-right: 35px;
            position: relative;

            a {
                align-items: center;
                color: $lightblue;
                display: flex;
                font-size: 14px;
                font-weight: 700;
                padding: 0;
                text-transform: uppercase;
                white-space: nowrap;
            }

            &:hover {
                > a {
                    color: $white;
                }
            }

            &.active {
                a {
                    color: $white;
                }

                &:before {
                    background-color: $grey;
                    content: '';
                    height: 30px;
                    left: 50%;
                    position: absolute;
                    top: 0;
                    transform: translateX(-50%);
                    width: 1px;
                }

                &:after {
                    background-color: $grey;
                    content: '';
                    height: 30px;
                    left: 50%;
                    position: absolute;
                    bottom: 0;
                    transform: translateX(-50%);
                    width: 1px;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        &-container {
            transition: none;
            text-align: left;
        }
    }

    &-column {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        height: 100px;
    }

    &-clipboard {
        position: relative;

        &-button {
            background-color: transparent;
            border: 1px solid $lightblue;
            color: $lightblue;
            display: inline-flex;
            font-weight: 700;
            padding-left: 6px;
            padding-right: 3px;
            width: 195px;

            &text {
                display: inline-block;
                margin: 0 17px;
            }
        }

        &-icon {
            font-size: 22px;
            transition: none;
        }

        &-counter {
            align-items: center;
            background-color: $lightblue;
            border-radius: 50%;
            color: $blue;
            display: inline-flex;
            font-size: 14px;
            height: 30px;
            justify-content: center;
            transition: background-color .5s ease, color .5s ease;
            width: 30px;
        }

        &-details {
            background-color: $lightblue;
            border-radius: 3px;
            border-top-right-radius: 0;
            color: $blue;
            padding: 0;
            position: absolute;
            right: 0;
            top: 100%;
            transform: scaleY(0);
            transform-origin: top;
            transition: transform .5s ease;
            width: 380px;
            z-index: 3;
        }

        &-list {
            list-style-type: none;
            margin-bottom: 0;
            padding: 30px;

            .item {
                align-items: center;
                display: flex;
                justify-content: space-around;
                margin-bottom: 20px;

                &-image {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 80px;
                    width: 80px;
                    transition: opacity .5s ease;

                    &:hover {
                        opacity: 0.5;
                    }
                }

                &-text {
                    font-size: 14px;
                    font-weight: 600;
                    flex: 1;
                    padding: 0 20px;
                    transition: color .5s ease;

                    &:hover {
                        opacity: rgba($blue, 0.5);
                    }
                }

                &-delete {
                    color: rgba($darkblue, 0.5);
                    cursor: pointer;
                    transition: color .5s ease;

                    &:hover {
                        color: rgba($darkblue, 1);
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        &-link {
            align-items: center;
            background-color: rgba($darkblue, 0.2);
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            color: $darkblue;
            cursor: pointer;
            display: flex;
            font-weight: 700;
            height: 55px;
            justify-content: center;
            text-decoration: underline;
            text-align: center;
            text-transform: uppercase;
            width: 100%;

            &:hover {
                background-color: rgba($blue, 0.2);
                text-decoration: underline;
            }
        }

        &:hover, &:focus, &.open {
            .header-clipboard-button {
                background-color: $lightblue;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                color: $blue;

                .header-clipboard-icon {
                    color: $blue;
                    transition: color .5s ease;
                }

                .header-clipboard-counter {
                    background-color: $blue;
                    color: $lightblue;
                }
            }

            .header-clipboard-details.has-items {
                transform: scaleY(1);
            }
        }
    }

    &-mobile {
        margin-left: 20px;
        position: relative;

        &-button {
            background-color: transparent;
            border: 1px solid $lightblue;
            color: $lightblue;
            display: inline-flex;
            font-weight: 700;
            padding-left: 6px;
            padding-right: 3px;
            width: 100px;

            &text {
                display: inline-block;
                margin-right: 10px;
            }
        }

        &-icon {
            position: relative;
            top: -1px;

            span {
                display: block;

                &, &:before, &:after {
                    display: block;
                    width: 20px;
                    height: 3px;
                    background-color: $lightblue;
                    position: relative;
                    transition-property: background-color;
                    transition-duration: .5s;
                    transition-timing-function: ease;
                }

                &:before, &:after {
                    content: '';
                }

                &:before {
                    top: -8px;
                }

                &:after {
                    bottom: -5px;
                }
            }
        }

        &-details {
            background-color: $lightblue;
            border-radius: 3px;
            border-top-right-radius: 0;
            color: $blue;
            padding: 0;
            position: absolute;
            right: 0;
            top: 100%;
            transform: scaleY(0);
            transform-origin: top;
            transition: transform .5s ease;
            width: 200px;
            z-index: 3;
        }

        &-menu {
            list-style-type: none;
            margin-bottom: 0;
            padding: 0;

            a {
                background-color: transparent;
                border-bottom: 1px solid rgba($blue, 0.5);
                color: $blue;
                display: block;
                font-weight: 600;
                padding: 10px 15px;
                text-transform: uppercase;
                width: 100%;

                &:hover {
                    background-color: $blue;
                    color: $lightblue;
                }
            }

            li.active {
                a {
                    background-color: $blue;
                    color: $lightblue;
                }
            }

            li:last-of-type {
                a {
                    border-bottom: 0;
                }
            }
        }

        &-link {
            align-items: center;
            background-color: rgba($darkblue, 0.2);
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            color: $darkblue;
            display: flex;
            font-weight: 700;
            height: 55px;
            justify-content: center;
            text-decoration: underline;
            text-align: center;
            width: 100%;

            &:hover {
                text-decoration: underline;
            }
        }

        &-lang {
            background-color: transparent;
            border-top: 1px solid rgba($blue, 0.5);
            color: $blue;
            display: none;
            font-weight: 600;
            padding: 10px 15px;
            text-transform: uppercase;
            width: 100%;

            &:hover {
                background-color: $blue;
                color: $lightblue;
            }
        }

        &:hover, &:focus {
            .header-mobile-button {
                background-color: $lightblue;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                color: $blue;

                .header-mobile-icon span {
                    &, &:before, &:after {
                        background-color: $blue;
                    }
                }
            }

            .header-mobile-details {
                transform: scaleY(1);
            }
        }
    }

    &-langbutton {
        background-color: transparent;
        border: 1px solid $lightblue;
        color: $lightblue;
        font-weight: 700;
        margin-left: 20px;
        width: 70px;

        &:hover {
            background-color: $lightblue;
            color: $blue;
        }
    }
}

main {
    padding-top: 100px;
}

.footer {
    background-color: $blue;
    color: $lightblue;

    &-menu {
        align-items: center;
        display: inline-flex;
        min-height: 90px;
        justify-content: flex-end;
        margin-bottom: 0;
        list-style-type: none;
        padding-left: 0;
        position: relative;

        li {
            align-items: center;
            display: flex;
            min-height: 90px;
            margin-right: 70px;
            position: relative;

            a {
                align-items: center;
                color: $lightblue;
                display: flex;
                font-size: 14px;
                font-weight: 700;
                padding: 0;
                white-space: nowrap;
            }

            &:hover {
                > a {
                    color: $white;
                }
            }

            &.active {
                a {
                    color: $white;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &-hr {
        border-bottom: 1px solid rgba($white, 0.3);
    }
    &-icons {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        list-style-type: none;
        margin-bottom: 0;
        min-height: 90px;
        padding: 0;

        li {
            margin-left: 30px;
        }

        a {
            color: rgba($white, 0.4);
            font-size: 35px;

            &:hover {
                color: rgba($white, 1);
            }
        }
    }

    &-copyright {
        color: $white;
        font-size: 14px;
        min-height: 90px;
        padding: 35px 0;

        &__right {
            text-align: right;
        }

        a {
            color: $white;
            display: inline-block;
            font-size: 14px;

            &:hover {
                color: $lightblue;
            }
        }
    }

    &-copyrightrow {
        a {
            color: $white;
            display: inline-block;
            font-size: 14px;

            &:hover {
                color: $lightblue;
            }
        }
    }

    &-link {
        color: $white;
        display: inline-block;
        font-size: 14px;

        &:hover {
            color: $lightblue;
        }
    }

    &-square {
        background-color: $grey;
        display: inline-block;
        height: 25px;
        margin: 5px 10px;
        width: 25px;
    }
}

.cke_widget_element {
    .footer-copyright {
        color: $blue;

        a {
            color: $blue;

            &:hover, &:focus, &:active {
                color: lighten($blue, 15%);
                text-decoration: none;
            }
        }
    }
}